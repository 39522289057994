// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bat-dong-san-js": () => import("./../../../src/pages/bat-dong-san.js" /* webpackChunkName: "component---src-pages-bat-dong-san-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-docs-react-atoms-js": () => import("./../../../src/pages/docs/react/atoms.js" /* webpackChunkName: "component---src-pages-docs-react-atoms-js" */),
  "component---src-pages-docs-react-contributing-js": () => import("./../../../src/pages/docs/react/contributing.js" /* webpackChunkName: "component---src-pages-docs-react-contributing-js" */),
  "component---src-pages-docs-react-functions-js": () => import("./../../../src/pages/docs/react/functions.js" /* webpackChunkName: "component---src-pages-docs-react-functions-js" */),
  "component---src-pages-docs-react-grid-js": () => import("./../../../src/pages/docs/react/grid.js" /* webpackChunkName: "component---src-pages-docs-react-grid-js" */),
  "component---src-pages-docs-react-intro-js": () => import("./../../../src/pages/docs/react/intro.js" /* webpackChunkName: "component---src-pages-docs-react-intro-js" */),
  "component---src-pages-docs-react-molecules-js": () => import("./../../../src/pages/docs/react/molecules.js" /* webpackChunkName: "component---src-pages-docs-react-molecules-js" */),
  "component---src-pages-docs-react-responsive-js": () => import("./../../../src/pages/docs/react/responsive.js" /* webpackChunkName: "component---src-pages-docs-react-responsive-js" */),
  "component---src-pages-docs-react-setup-js": () => import("./../../../src/pages/docs/react/setup.js" /* webpackChunkName: "component---src-pages-docs-react-setup-js" */),
  "component---src-pages-docs-react-showcase-js": () => import("./../../../src/pages/docs/react/showcase.js" /* webpackChunkName: "component---src-pages-docs-react-showcase-js" */),
  "component---src-pages-docs-react-spacing-js": () => import("./../../../src/pages/docs/react/spacing.js" /* webpackChunkName: "component---src-pages-docs-react-spacing-js" */),
  "component---src-pages-docs-react-theme-js": () => import("./../../../src/pages/docs/react/theme.js" /* webpackChunkName: "component---src-pages-docs-react-theme-js" */),
  "component---src-pages-docs-react-ui-templates-js": () => import("./../../../src/pages/docs/react/ui-templates.js" /* webpackChunkName: "component---src-pages-docs-react-ui-templates-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-giao-duc-js": () => import("./../../../src/pages/giao-duc.js" /* webpackChunkName: "component---src-pages-giao-duc-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refund-js": () => import("./../../../src/pages/refund.js" /* webpackChunkName: "component---src-pages-refund-js" */),
  "component---src-pages-spa-js": () => import("./../../../src/pages/spa.js" /* webpackChunkName: "component---src-pages-spa-js" */),
  "component---src-pages-tai-chinh-js": () => import("./../../../src/pages/tai-chinh.js" /* webpackChunkName: "component---src-pages-tai-chinh-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

